import { format, parseISO } from "date-fns"
import BlueIndexLink from "../components/link/blue-index-link"
import MarkdownBody from "../components/markdown-body"
import { RECORDS_PER_PAGE } from "../constants"
import SideLayout from "./side-layout"
import IDataPageProps from "../interfaces/data-page-props"
import * as React from "react"
import ToBlueLink from "../components/link/to-blue-link"

interface IProps extends IDataPageProps {
  title: string
}

function PostsLayout({ title, data, location }: IProps) {
  const posts = data.posts.nodes

  const pages = Math.floor(
    (posts.length + RECORDS_PER_PAGE - 1) / RECORDS_PER_PAGE
  )

  return (
    <SideLayout title={title} location={location}>
      <ul className="mt-8">
        {posts.map((post: any, index: number) => {
          const url = `/${title.toLowerCase()}${post.fields.slug}`
          return (
            <li
              key={index}
              className="color-ani block rounded-lg bg-apple-gray p-8"
            >
              <p>{format(parseISO(post.fields.date), "LLLL d, yyyy")}</p>
              <ToBlueLink href={url} ariaLabel="View article">
                <h2 className="mt-2 text-2xl font-medium">
                  {post.frontmatter.title}
                </h2>
              </ToBlueLink>
              <MarkdownBody html={post.excerpt} className="mt-2" />
              <BlueIndexLink href={url} ariaLabel="View article">
                Read more
              </BlueIndexLink>
            </li>
          )
        })}
      </ul>
      <></>
    </SideLayout>
  )
}

export default PostsLayout
